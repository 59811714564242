<template>
  <div class="body fixed mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <!-- <div class="spinner"><div><div></div></div></div> -->
    <!-- Header -->
    <section class="header notice mt-">
      <div class="flexH width">
        <a class="icon iconButton third" @click.prevent="goBack">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span class="fL nowrap">確認兌換內容</span>
      <div class="flexH width right"></div>
    </section>

    <!-- Main -->
    <section class="main notice">
      <!-- 紀錄 -->
      <div class="records flexV width padding">
        <div class="record p-4 max-w-md mx-auto bg-white rounded-lg mb-5">
          <div class="mb-4">
            <div class="fS nowrap gray">折抵券名稱</div>
            <input
              type="text"
              class="fL yl-input"
              placeholder=""
              :value="currentCoupon.title"
              disabled
            />
          </div>

          <div class="hr"></div>

          <div class="mb-4">
            <div>
              <!-- class: error -->
              <div class="fS nowrap gray">營運單位</div>
              <input
                type="text"
                class="fL yl-input"
                placeholder=""
                :value="currentCoupon.company"
                disabled
              />
            </div>
          </div>

          <div class="hr"></div>

          <div class="mb-4">
            <div>
              <!-- class: error -->
              <div class="fS nowrap gray">兌換一次所需雲林幣</div>
              <input
                type="text"
                class="fL yl-input"
                placeholder=""
                :value="currentCoupon.pay"
                disabled
              />
            </div>
          </div>
        </div>

        <div
          class="record p-4 max-w-md mx-auto bg-white rounded-lg mb-5 shadow-md"
        >
          <div class="flex items-center justify-between">
            <div class="text-lg">兌換數量</div>
            <div class="flex items-center ml-4">
              <!-- <button
                @click="decrement"
                class="input-number mr-3"
                style="align-items: end"
              >
                -
              </button> -->
              <span
                class="bg-white text-gray-800 py-1 px-4 border-t border-b"
                >{{ form.quantity }}</span
              >
              <!-- <button
                @click="increment"
                class="input-number ml-3"
              >
                +
              </button> -->
            </div>
          </div>
        </div>

        <div
          class="record p-4 max-w-md mx-auto bg-white shadow-md rounded-lg mb-5"
        >
          <div class="mb-4">
            <div class="text-lg">兌換結算</div>
            <div class="result-container">
              <div class="flex justify-between">
                <div>持有雲林幣:</div>
                <div>{{ totalCoins }} 枚</div>
              </div>
              <div class="flex justify-between">
                <div>扣除雲林幣:</div>
                <div>{{ totalDeduction }} 枚</div>
              </div>
              <div class="flex justify-between">
                <div>剩餘雲林幣:</div>
                <div :class="submitButtonDisabled? 'text-danger' : ''">{{ remainingCoins }} 枚</div>
              </div>
            </div>
          </div>
        </div>

        <div class="buttons">
          <button :disabled="submitButtonDisabled" @click="exchangeCoupon">確定兌換</button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';

import useCoupon from '@/utils/useCoupon';

export default {
  data() {
    return {
      form: {
        quantity: 1
      }
    };
  },
  created() {
    this.$store.dispatch("getUserPoints");
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters({
      userPoints: "getTyUserPoints",
      parkingCoupons: 'coupon/parkingCoupons'
    }),
    totalDeduction() {
      return this.form.quantity * this.currentCoupon.pay;
    },
    totalCoins() {
      return this.userPoints.pointBalance;
    },
    remainingCoins() {
      return this.totalCoins - this.totalDeduction;
    },
    currentCoupon() {
      return this.getCouponDetail(this.$route.params.id);
    },
    submitButtonDisabled() {
      return this.remainingCoins < 0
    }
  },
  methods: {
    getCouponDetail(couponId) {
      return this.parkingCoupons.find(coupon => coupon.id === couponId) || {};
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    async exchangeCoupon() {
      console.log('exchangeCoupon');
      // TODO: Send api
      const data = {
        phoneNumber: this.user.phone,
        amount: this.currentCoupon.pay,
        exchangeTime: moment().format('YYYY/MM/DD HH:mm:ss'),
        transactionNumber: uuidv4(),
        accessToken: ""
      };
      console.log('3991 data', data);
      const res = await useCoupon().exchange(data)
      console.log('3991 res', res);
      const storeData = {
        id: uuidv4(),
        couponId: this.currentCoupon.id,
        couponNumber: res.data.couponNumber,
        title: this.currentCoupon.title,
        company: this.currentCoupon.company,
        type: this.currentCoupon.type,
        pay: this.currentCoupon.pay,
        isUsed: false,
      }
      console.log('3991 storeData', storeData);
      this.$store.dispatch('coupon/addUserCoupon', storeData);
      this.$notify({
        group: 'normal',
        type: 'success',
        title: '兌換成功',
        duration: 3000,
      })
      this.goBack()
    }
  },
};
</script>

<style lang="scss" scoped>
.yl-input {
  width: 100%;
}
.hr {
  border: 1px solid #e8e8e8;
  margin: 16px auto;
}

.record {
  padding: 16px !important;
  margin-bottom: 16px !important;
}

.result-container {
  line-height: 2;
  color: #636363;
  font-size: 18px;
}

button {
  color: white;
  height: 51px !important;

  &:disabled {
    background: #BDBDBD;
    cursor: not-allowed;
  }
}

.input-number {
  width: 20px;
  height: 20px !important;
}

</style>

<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/unique.css"></style>
<style scoped src="@/assets/css/payment.css"></style>
