<template>
  <div class="dialog-wrapper">
    <dialog open class="dialog" @click.prevent="handleDialog">
      <h2 class="title">注意事項</h2>
      <ol>
        <li>折抵券過期後將無法使用，已兌換的雲林幣恕不退回。</li>
        <li>完成兌換即無法返還雲林幣。</li>
        <li>目前「路邊停車」折抵券暫無使用期限。</li>
      </ol>
      <div class="buttons">
        <button class="button-outline" @click="notShowAgain">不在顯示</button>
        <button @click="justClose">確認</button>
      </div>
    </dialog>
  </div>
</template>

<script>
export default {
  name: 'ParkingNoticeDialog',
  methods: {
    handleDialog(e) {
      e.stopPropagation();
    },
    notShowAgain() {
      localStorage.setItem('notShowParkingNotice', '1');
      this.justClose()
    },
    justClose() {
      this.$emit('close');
    }
  },
};
</script>

<style lang="scss" scoped>
.dialog-wrapper {
  position: fixed;
  inset: 0;
  z-index: 100;
  background-color: #000000CC;
  height: 100svh;
  width: 100svw;

  .dialog {
    top: 50%;
    transform: translateY(-50%);
    border: none;
    border-radius: 20px;
    margin-left: 32px;
    margin-right: 32px;
  }

  ol {
    margin-bottom: 0;
  }

  .title {
    text-align: center;
    font-size: 18px;
  }

  .buttons {
    display: flex;
    justify-content: center;
    gap: 12px;

    button {
      border-radius: 16px;
      color: #fff;
      background-color: var(--k1);
      border: 1px solid var(--k1);
      width: 100%;
      height: 40px;

      &.button-outline {
        color: var(--k1);
        background-color: #fff;
      }
    }
  }
}
</style>