<template>
  <div class="body mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <!-- <div class="spinner"><div><div></div></div></div> -->
    <!-- Header -->
    <section class="header notice">
      <div class="flexH width">
        <a class="icon iconButton third" @click.prevent="goBack">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span class="fL nowrap font-bold font-white">數位圖書證</span>
      <div class="flexH width right"></div>
    </section>

    <!-- Main -->
    <section class="main notice">
      <!-- 紀錄 -->
      <div class="records flexV width padding">
        <div class="record mt-2">
          <div class="flexH width relative mb-1">
            <div class="flexV width mr-3 line-height leading-8">
              <div class="fS text-gray-500">雲林縣公共圖書館</div>
              <div v-if="status === 0">
                <div class="fL">尚未綁定圖書證</div>
                <div class="fS">-</div>
              </div>
              <div v-if="status === 1">
                <div class="fL">蔡函瑄</div>
                <div class="fS">10640003645993</div>
              </div>
              <div v-if="status === 2">
                <div class="fL">楊繼逸</div>
                <div class="flex items-center">
                  <div class="fS" style="margin-right: 4px">10640003645952</div>
                  <img src="@/assets/icon/flag.svg" alt="" />
                </div>
              </div>
            </div>
            <div class="gap-1">
              <img
                v-if="status === 0"
                src="@/assets/img/binding01.png"
                alt=""
              />
              <img
                v-if="status === 1"
                src="@/assets/img/binding02.png"
                alt=""
              />
              <img
                v-if="status === 2"
                src="@/assets/img/binding03.png"
                alt=""
              />
            </div>
          </div>
          <div class="buttons">
            <div v-if="status === 0">
              <button @click="add()">新增數位圖書證</button>
            </div>
            <div v-else class="flex">
              <button class="mr-1 button-outline" @click="change()">
                切換圖書證
              </button>
              <button @click="qrcode()">圖書證 QR code</button>
            </div>
          </div>
        </div>
      </div>

      <div class="records flexV width padding">
        <div class="record mt-2 flex justify-around">
          <a class="text-center" href="/digital-card/task">
            <img src="@/assets/icon/book01.svg" alt="" />
            <div>圖書任務</div>
          </a>
          <a class="text-center" href="/digital-card/borrowing-list">
            <img src="@/assets/icon/book02.svg" alt="" />
            <div>借閱清單</div>
          </a>
          <a class="text-center" href="/digital-card/reserve">
            <img src="@/assets/icon/book03.svg" alt="" />
            <div>線上預約</div>
          </a>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "",
  data() {
    return {
      latestReadAt: 0,
      status: 1,
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    console.log(to, from);
    next((vm) => {
      let that = vm;
      if (that.user && that.user.userId) {
        //
      } else {
        // window.location.reload();
        next("/home");
      }
    });
  },
  mounted() {
    console.log("mounted!");
    // this.onSnapShot();

    //NOTE: Howard's fix: 現在notification直接從store取用，不用再原地去Firestore撈了
    // this.getLatestReadTime()
    // .then(() => {
    //     this.onSnapShot();
    // });

    setTimeout(() => {
      console.log("Notifications", this.notifications);
    }, 4000);
  },
  computed: {
    ...mapState(["user"]),
    // ...mapGetters({
    //     notifications: 'noti/notifications'
    // }),
    notifications() {
      let ns = this.$store.getters["noti/notifications"];
      // fake data
      // ns = [{id:1,title:"title1",text:"text1",ts:1720961906,unread:true},{id:2,title:"title2",text:"text2",ts:1720961906,unread:true}];
      return Object.values(ns).sort((a, b) => {
        return b.ts - a.ts;
      });
    },
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    add() {
      this.$router.push("/digital-card/add");
    },
    change() {
      this.$router.push("/digital-card/change");
    },
    qrcode() {
      // this.$router.push("/digital-card/qrcode");
    },
  },
};
</script>

<style scoped>
.tab {
  border-radius: 0 !important;
}

.record {
  padding: 16px !important;
}

button {
  color: white;
  border-radius: 16px !important;
}

.button-outline {
  color: var(--k1) !important;
  border-radius: 16px !important;
  background-color: #fff;
  border: 1px solid var(--k1);
}
</style>

<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/unique.css"></style>
<style scoped src="@/assets/css/payment.css"></style>
