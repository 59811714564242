import axios from 'axios'

const useCoupon = () => {
  const parkingApiHost = process.env.VUE_APP_PARKING_API_HOST
  const exchangeParkingCouponUrl = `${parkingApiHost}/NubiWebApi/api/Nubi/GetCouponNumber`
  const signUrl = `${parkingApiHost}/NubiWebApi/api/Nubi/SignIn`

  console.log('exchangeParkingCouponUrl', exchangeParkingCouponUrl);
  console.log('getAccessToken', getAccessToken);

  const exchange = async (data) => {
    // TODO: 3991 Test api
    // const token = await getAccessToken()
    // console.log('token', token);
    console.log('data', data);
    // return axios.post(exchangeParkingCouponUrl, data)
    return {
      data: {
        "returnCode": "00",
        "returnMsg": "",
        "couponNumber": "839D9582A10C41C7BE83",
        "transactionNumber": "1234567890"
      }
    }
  }

  const getAccessToken = async () => {
    const info = await getClientInformation()
    const response = await axios.post(signUrl, info)
    console.log('response', response);
    return response.data.accessToken
  }

  const getClientInformation = async () => {
    // TODO: 3991 Use env ?
    return {
      "clientId": "bnViaQ==",
      "clientSecret": "UWNTNlRVa2pWWGlaWlIwV3JGYlJRR0I5THBSRm02Wk5MUklCL0RvbFkxWT0yMDI0MDkxMDA5MjIzMw=="
    }
  }

  return {
    exchange
  }
}

export default useCoupon